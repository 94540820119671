/* eslint-disable no-console */
import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { ApiRestErrorInterface } from 'src/interfaces/ApiRestErrorInterface';
import { Dialog } from 'quasar';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}
const endPoint = process.env.DEV ? '/api' : `${window.location.href.split('.')[0]}.host.roomdoo.com/api`;
// const endPoint = 'https://staging.odoo.aldahotels.moduon.net/api';
const api = axios.create({ baseURL: endPoint });

export default boot(({ app, redirect }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$api = api;

  api.interceptors.response.use(
    (response) => response,
    (error: ApiRestErrorInterface) => {
      if (error.response && error.response.config.url !== '/login') {
        console.log('getting error responses from axios interceptor');
        if (error.response.status === 403 || error.response.status === 401) {
          console.log('redirecting to login from axios interceptor');
          redirect('/login');
        } else {
          console.log('showing error dialog from axios interceptor');
          Dialog.create({
            title: 'Algo ha ido mal',
            message: error.response.data.description,
            html: true,
          });
        }
      }
      return Promise.reject(error);
    },
  );
});

export { api };
